* {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.playarea {
    display: block;
    margin: 0;
    padding: 0;
    z-index: -100;
    width: 100%;
    height: 100%;
    user-select: none;
}

canvas:focus {
    outline: none;
}

.minimap {
    position: absolute;
    top: 100px;
    right: 10px;
    z-index: 100;
    border: 1px solid #fff;
    margin: 0;
    padding: 0;
    width: 200px;
    height: 200px;
}

.splash {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    height: 100%;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #eeeeee;
    background: #22222288;
}

.toolbar {
    position: absolute;
    top: 0;
    left: 0;
    background: #444;
    width: 100%;
    user-select: none;
}

.toolbar button {
    font-size: 10pt;
    color: #eee;
    text-shadow: #222222;
    text-decoration: none;
    background: none;
    border: none;
    padding: 10px 15px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #444;
    padding: 5px 5px;
    color: #eeeeee;
    font-size: 10px;
    text-align: center;
}

.footer a {
    color: #eeeeee;
    text-decoration: none;

}

.progress-bar {
    display: inline-block;
    height: 20px;
    width: 100px;
    position: relative;
    padding: 10px;
}

.progress-bar>span {
    text-align: center;
    display: block;
    background: red;
    overflow: visible;
    height: 100%;
    white-space: nowrap;
}


.help, .settings {
    float: right;
}

.inventory, .player {
    padding-right: 20px;
}

.dialog {
    position: absolute;
    top: 100px;
    left: 100px;
    background: #444;
    width: 500px;
    font-size: 12pt;
    color: #eee;
    text-shadow: #222222;
    border: 1px #666 solid;
}

.dialog-titlebar {
    padding: 10px 20px;
    font-size: 24pt;
    line-height: 1;
}


.dialog-titlebar strong {
    vertical-align: middle;
    display: inline-block;
}

.dialog-close {
    float: right;
    display: inline-block;
    cursor: pointer;
    background: none;
    font-size: 24pt;
    color: #eee;
    text-shadow: #222222;
    border: none;
    font-weight: bolder;
}

.dialog-body {
    clear: both;
    margin: auto;
    padding: 10px 20px;
    background: #666;
    height: 100%;
    min-height: 100px;
}

.dialog-body h4 {
    padding-bottom: 10px;
}

.dialog-body input[type=checkbox] {
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
    margin-right: 20px;
}

.dialog-body p {
    margin: auto;
    padding-bottom: 25px;
}

.clear {
    clear: both;
}

.inventory-item {
    float: left;
    width: 96px;
    margin: auto;
    padding: 5px;
    text-align: center;
    display: inline-block;
    height: 96px;
}

.inventory-item.equipped {
    background: #aaa;
    font-weight: bold;
}

.inventory-item p  {
    text-align: center;
}

.inventory-item img {
    margin: auto;
}

.player-values {
    padding-bottom: 10px;
}

.log {
    bottom: 20px;
    padding: 10px;
    color: #cccccc;
    font-size: 10pt;
    width: 100%;
    position: absolute;
    user-select: none;
}

.log .debug {
    text-shadow: 0px 0px 5px #f55;
}

.log .notice {
    text-shadow: 0px 0px 5px #ff5;
}

.log .info {
    text-shadow: 0px 0px 5px #55f;
}


.stats {
    position: absolute;
    color: #eeeeee;
    right: 0;
    top: 50px;
    width: 200px;
    user-select: none;
}

.join {
    width: 60%;
    margin: 50px auto;
    color: #fff;
}

.join label {
    padding: 0px 5px;
    font-weight: bold;
    font-size: 18px;
}

.join fieldset {
    padding: 50px 50px;
    text-align: center;
    background: #333;
    border: 1px #666 solid;
}

.join legend {
    background: #888;
    padding: 15px 25px;
    border: 1px #666 solid;
    font-weight: bold;
    font-size: 36px;
    color: #eee;
}

.join input {
    padding: 5px 5px;
    margin-right: 10px;
    font-size: 18px;
    background: #ccc;
}

.join input[type=submit] {
    font-weight: bold;
}


.server-stats {
    color: #eee;
    text-align: center;
}
